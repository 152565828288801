import {
  FormikPasswordInputField,
  FormikSubmit,
  FormikTextInputField,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import * as Yup from "yup";
import { useSearchParam } from "react-use";

import { LogoTitlePage } from "../components/logo-title-page";
import { REDIRECT_DELAY } from "../lib/constants";
import { parseFormError } from "../lib/forms";
import { useResetPassword } from "../lib/mutations/password";
import { useIsValidPasswordReset } from "../lib/queries/password";
import { useDelayedPageLoader } from "../hooks/use-delayed-page-loader";

export function ResetPassword() {
  const { spacing } = useTheme();
  const token = useSearchParam("token");

  const { data, isLoading, isError } = useIsValidPasswordReset(token || "");
  const { mutate: resetPassword, isSuccess } = useResetPassword(token);

  const { isLoading: showLoadingState } = useDelayedPageLoader();

  const state: ResetPasswordPageState = useMemo(() => {
    if (isSuccess) return "success";
    if (showLoadingState || isLoading) return "loading";
    if (isError) return "error";
    if (!data?.is_valid) return "invalid";
    if (data?.is_valid) return "valid";
    return "error";
  }, [isSuccess, showLoadingState, isLoading, isError, data?.is_valid]);

  const tfaEnabled: boolean = useMemo(
    () => data?.tfa_enabled,
    [data?.tfa_enabled]
  );

  return (
    <LogoTitlePage title={stateToTitle[state]}>
      {state === "valid" ? (
        <Formik<typeof initialValues>
          validationSchema={() => validationSchema(tfaEnabled)}
          initialValues={initialValues}
          onSubmit={({ password, otp }, { setFieldError, setSubmitting }) => {
            resetPassword(
              { password, otp },
              {
                onSettled: () => setSubmitting(false),
                onSuccess: () => {
                  setTimeout(() => {
                    window.location.assign("/");
                  }, REDIRECT_DELAY);
                },
                onError: (error) => {
                  parseFormError({
                    error,
                    setFieldError,
                    defaultField: "password",
                  });
                },
              }
            );
          }}
        >
          <Form>
            <VStack spacing={spacing.sm}>
              <FormikPasswordInputField
                name="password"
                label="Password"
                required
              />
              <FormikPasswordInputField
                name="confirmPassword"
                label="Confirm Password"
                required
              />
              {tfaEnabled ? (
                <FormikTextInputField
                  name="otp"
                  label="One Time Password"
                  textInputProps={{ textContentType: "oneTimeCode" }}
                  required
                />
              ) : null}
              <FormikSubmit />
            </VStack>
          </Form>
        </Formik>
      ) : null}
    </LogoTitlePage>
  );
}

type ResetPasswordPageState =
  | "success"
  | "loading"
  | "error"
  | "invalid"
  | "valid";

const stateToTitle: Record<ResetPasswordPageState, string> = {
  success: "Password reset, you may now sign in to the app",
  loading: "Validating password reset...",
  error: "Network error",
  invalid: "Password reset is not valid",
  valid: "Please set a new password",
};

const initialValues = {
  password: "",
  confirmPassword: "",
  otp: "",
};

const validationSchema = (tfaEnabled = false) =>
  Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Password confirmation is required"),
    ...(tfaEnabled
      ? {
          otp: Yup.string()
            .required("One time password is required")
            .min(6, "Must be at least 6 characters"),
        }
      : {}),
  });
